<template>
  <div>
    <v-form ref="checklistForm">
      <v-row class="ma-0">
        <template v-for="(item, index) in listOfFields">
          <v-col v-if="!item.isPanelField" :key="index" class="pa-0 mb-4"
          :cols="$vuetify.breakpoint.mdAndDown ? $vuetify.breakpoint.xsOnly || [10, 15, 19].includes(item.type) ? '12' : '6' : item.columns && ![10, 15, 19].includes(item.type) ? item.columns : '12'" :md="item.columns && ![10, 15, 19].includes(item.type) ? item.columns : [10, 11, 12, 14, 15, 19].includes(item.type) ? '12' : '6'">
            <template v-if="item.type === 1">
              <v-text-field dense outlined hide-details :label="item.label" :rules="getValidation(item.validation) || []" v-model="fields[item.name]" :placeholder="item.placeholder"></v-text-field>
            </template>
            <template v-if="item.type === 2">
              <v-textarea v-model="fields[item.name]" outlined :label="item.label" dense :placeholder="item.placeholder"
                :rules="getValidation(item.validation) || []">
              </v-textarea>
            </template>
            <template v-if="item.type === 3">
                <template v-if="item.default_value.checkboxtype === 1">
                  <v-checkbox :label="item.label" dense></v-checkbox>
                </template>
                <template v-else>
                  <v-row class="ma-0" v-if="item.default_value.viewType === 'horizontal'">
                      <v-col cols="12" class="pa-0 scroll-styles" style="overflow-x:auto">
                        <v-card flat :class="['d-flex flex-row scroll-width','pa-0']"  tile>
                          <v-card flat tile :width="`${getCheckboxLabelWidth(options)}px`" min-height="60px" :min-width="`${getCheckboxLabelWidth(options)}px`"
                            v-for="(options, index) in item.default_value.options.filter( x => x.isactive)" :key="index">
                            <v-row class="ma-0" no-gutters>
                              <v-checkbox class="mx-1 mt-1 checkbox_position" style="max-width:100px" hide-details v-model="fields[item.name]" :label="options.label" :value="options.value" multiple></v-checkbox>
                            </v-row>
                          </v-card>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row v-else-if="item.default_value.viewType === 'vertical'">
                      <v-col class="pa-0 ma-0" cols="12" v-for="(options, index) in item.default_value.options.filter( x => x.isactive)" :key="index">
                          <v-checkbox class="mx-1 mt-1 checkbox_position" hide-details v-model="fields[item.name]" :label="options.label" :value="options.value" multiple></v-checkbox>
                      </v-col>
                    </v-row>
                    <v-row v-else>
                      <v-card flat tile :width="`${getCheckboxLabelWidth(options)}px`" class="text-truncate pa-0" min-height="60px" min-width="70"
                        v-for="(options, index) in item.default_value.options.filter( x => x.isactive)" :key="index">
                        <v-row class="ma-0" no-gutters>
                        <v-checkbox class="mx-1 mt-1 checkbox_position" hide-details v-model="fields[item.name]" :label="options.label" :value="options.value" multiple></v-checkbox>
                        </v-row>
                      </v-card>
                    </v-row>
                </template>
            </template>
            <template v-if="item.type === 4">
              <span class="font-weight-bold">{{item.label}}</span>
              <v-radio-group v-model="fields[item.name]" row>
                <v-radio v-for="(option, index) in item.default_value.options.filter(x => x.isactive)" :label="option.label"
                  :rules="getValidation(item.validation) || []" :value="option.value" :key="index"></v-radio>
              </v-radio-group>
            </template>
            <template v-if="item.type === 5">
              <template v-if="item.default_value.isAPI">
                <v-autocomplete v-model="fields[item.name]"
                  :multiple="item.default_value.is_multiselect" :items="item.default_value.options" :placeholder="item.placeholder"
                  outlined dense :item-text="item.default_value.name" :loading="item.isLoading"
                  @keyup="getPublicApiData(item.default_value, index)" item-value="value" clearable hide-details>
                </v-autocomplete>
              </template>
              <template v-else>
                <v-autocomplete :items="item.default_value.options.filter(x => x.isactive)" :label="item.label"
                  v-model="fields[item.name]" :multiple="item.default_value.is_multiselect" :placeholder="item.placeholder"
                  outlined dense item-text="label" item-value="value" clearable hide-details>
                </v-autocomplete>
              </template>
            </template>
            <template v-if="item.type === 6">
              <v-menu v-model="item.date_menu" :close-on-content-click="false" max-width="290">
                <template v-slot:activator="{ on }">
                  <v-text-field dense outlined hide-details v-model="fields[item.name]" :placeholder="item.placeholder"
                    clearable prepend-icon="mdi-calendar" :label="item.label" readonly v-on="on" @click:clear="fields[item.name] = null"></v-text-field>
                </template>
                <v-date-picker no-title  v-model="item.date_value" @input="fields[item.name] = $formatter.parseDate(item.date_value), item.date_menu = false"></v-date-picker>
              </v-menu>
            </template>
            <template v-if="item.type === 7">
              <v-menu :ref="`time_menu_${index}`" v-model="item.time_menu" :close-on-content-click="false" :nudge-right="40"
                :return-value.sync="fields[item.name]" transition="scale-transition" offset-y max-width="290" min-width="290">
                <template v-slot:activator="{ on }">
                  <v-text-field dense outlined hide-details v-model="fields[item.name]" :rules="getValidation(item.validation) || []"
                    :placeholder="item.placeholder" clearable prepend-icon="mdi-timer"
                    :label="item.label" readonly v-on="on" @click:clear="fields[item.name] = null">
                  </v-text-field>
                </template>
                <v-time-picker v-if="item.time_menu" v-model="fields[item.name]" format="24hr" full-width
                  @click:minute="$refs[`time_menu_${index}`][0].save(fields[item.name])">
                </v-time-picker>
              </v-menu>
            </template>
            <template v-if="item.type === 8">
              <v-switch dense inset :label="item.label" :rules="getValidation(item.validation) || []" v-model="fields[item.name]" hide-details></v-switch>
            </template>
            <template v-if="item.type === 9">
              <v-file-input :label="item.label" v-model="fields[item.name]" :placeholder="item.placeholder" accept=".xlsx, .xls, .doc, .docx, .ppt, .pptx, .txt, .pdf, image/png, image/jpeg, image/jpg, image/gif"
                @change="uplaodDocumentsHandler(item._id, item.name, fields[item.name], item.type)" multiple outlined dense hide-details :rules="getValidation(item.validation) || []">
              </v-file-input>
              <v-row class="ma-0 ml-8" :key="reRender">
                <v-col cols="12"  v-for="(document, docIndex) in documentsList[item.name]" :key="docIndex">
                  <template v-if="document._id">
                    <v-row>
                    <span class="pa-0 ma-0 my-auto mr-3">{{ document.file_original_name }}</span>
                    <v-spacer></v-spacer>
                    <v-btn icon color="error" @click="editDocument(document, item.name)">
                      <v-icon size="17">mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn icon color="info" @click="downloadDocument(document._id)">
                      <v-icon size="20">mdi-download</v-icon>
                    </v-btn>
                    <v-btn icon color="error" @click="deleteDocument(document._id, item.name, item.type)">
                      <v-icon size="20">mdi-window-close</v-icon>
                    </v-btn>
                    </v-row>
                  </template>
                  <template v-else>
                    <template v-for="(document,indx) in document.getFiles">
                      <v-row :key="indx" class="ma-0">
                        <div class="font-weight-medium">{{ document.name }}</div>
                        <v-spacer></v-spacer>
                        <v-icon color="blue darken-4" class="ma-2" size="20">mdi-file-upload</v-icon>
                      </v-row>
                    </template>
                  </template>
                </v-col>
              </v-row>
            </template>
            <template v-if="item.type === 10">
              <survey-and-advanceSurvey :item="item" :fields="fields[item.name]"
                  :listOfDocuments="documentsList" :fromChecklistForm="true" :expandsurveysection="false"
                  @uploadDocument="uplaodDocumentsHandler" @downloadDocument="downloadDocument"
                  @deleteDocument="deleteDocument" @editDocument="editDocument">
              </survey-and-advanceSurvey>
            </template>
            <template v-if="item.type === 11">
              <span class="font-weight-medium">{{ item.label }}</span>
              <v-divider></v-divider>
            </template>
            <template v-if="item.type === 12">
              <v-card class="d-flex align-content-start flex-wrap pa-0" flat tile>
                <v-card min-width="80" v-for="(grid, index) in item.default_value.grid_data.labels" :key="index" class="pa-0 pl-1 text-left" outlined tile>
                  <span class="subtitle-2 d-inline-block text-truncate" style="max-width:70px;">{{ grid.label }}</span>
                </v-card>
              </v-card>
              <v-card class="d-flex align-content-start flex-wrap" flat tile v-for="(row, rIndex) in item.default_value.grid_data.data" :key="rIndex">
                <v-card max-width="80" class="pa-0" outlined tile v-for="(grid, dIndex) in row" :key="dIndex">
                  <v-text-field single-line dense flat full-width v-model="grid.value" solo hide-details></v-text-field>
                </v-card>
              </v-card>
              <v-btn class="mt-2" fab dark x-small color="primary" @click="addnewLineOnGrid(index, item.label)">
                <v-icon  dark>mdi-plus</v-icon>
              </v-btn>
            </template>
            <template v-if="item.type === 13">
              <span v-if="item.label" class='pa-0 font-weight-bold'>{{ item.label }}:</span>
              <VueSignaturePad width="100%" height="180px" class="signature--pad" :class="$vuetify.theme.dark ? 'signature--pad--dark' : ''" :ref="`signaturePad_${item._id}`"/>
              <v-btn color="info" fab x-small dark  title="reset" class="mt-1" @click="clearThisSignature(item._id)">
                <v-icon>mdi-cached</v-icon>
              </v-btn>
            </template>
            <template v-if="item.type === 14">
              <v-card class="pa-1">
                <v-card-title class="pa-1 caption font-weight-bold">{{ item.label }}</v-card-title>
                <v-divider></v-divider>
                  <v-card-text class="pa-1">
                    <form-render :listOfFields="item.panel_fields" :fields="fields" ref="panelFormReference" :documentsList="documentsList" :checklistId="checklistId"></form-render>
                  </v-card-text>
                </v-card>
            </template>
            <template v-if="item.type === 15">
              <invent-component :gridLabels="item.default_value.grid_header.labels" :gridValues="item.default_value.grid_data.values"></invent-component>
            </template>
            <template v-if="item.type === 16">
              <div v-html="item.default_value.html_content"></div>
            </template>
            <template v-if="item.type === 17">
              <span class='pa-0 subtitle-2' :style="{ fontSize: `${item.default_value.font_size}em !important` }">{{ item.label }}</span>
            </template>
            <template v-if="item.type === 18">
              <v-simple-table class="bordered--table scroll-styles" v-if="item.default_value && item.default_value.grid_data && item.default_value.grid_data.labels">
                <thead>
                  <tr>
                    <th style="height:34px" :style="`min-width:${grid.width}px`"
                      v-for="(grid, index) in item.default_value.grid_data.labels" :key="index">
                      {{ grid.label }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(trData, trIndex, rowIndexValue) in item.default_value.grid_data.data" :key="trIndex">
                    <td v-for="(tdGrid, tdIndex) in item.default_value.grid_data.labels" :key="tdIndex">
                      <template  v-if="trData[tdIndex]">
                        <template v-if="tdGrid.field.type === fieldTypeConstants.STRING || tdGrid.field.type === fieldTypeConstants.NUMBER">
                          <v-text-field v-model="trData[tdIndex].value" :placeholder="tdGrid.field.type === fieldTypeConstants.STRING ? 'Text' : 'Number'"
                            dense hide-details :type="tdGrid.field.type === fieldTypeConstants.NUMBER ? 'number' : 'text'"></v-text-field>
                        </template>
                        <template v-if="tdGrid.field.type === fieldTypeConstants.CHECKBOX && tdGrid.field.typedata">
                          <v-row class="ma-0">
                            <v-col cols="12">
                              <v-checkbox v-for="(options, index) in tdGrid.field.typedata.listItems.filter(x => x.isactive)" :key="index" v-model="trData[tdIndex].value"
                                class="mt-0  pa-1 mx-1" hide-details :label="options.label" :value="options.value" multiple></v-checkbox>
                            </v-col>
                          </v-row>
                        </template>
                        <template v-if="tdGrid.field.type === fieldTypeConstants.RADIOBOX && tdGrid.field.typedata">
                          <v-row v-if="tdGrid.field.typedata.listItems">
                            <v-radio-group class="mt-0" row hide-details v-model="trData[tdIndex].value">
                              <v-radio v-for="(options, index) in tdGrid.field.typedata.listItems.filter(x => x.isactive)" :key="index" :label="options.label" :value="options.value"></v-radio>
                            </v-radio-group>
                          </v-row>
                        </template>
                        <template v-if="tdGrid.field.type === fieldTypeConstants.SELECT && tdGrid.field.typedata">
                          <v-autocomplete dense :items="tdGrid.field.typedata.listItems.filter(x => x.isactive)" :multiple="tdGrid.field.typedata.isMultiSelect" clearable hide-details
                            item-text="label" item-value="value" v-model="trData[tdIndex].value">
                            <template v-slot:selection="{ item, index }">
                              <span v-if="index === 0">{{ item.label }}</span>
                              <span v-if="index === 1" class="grey--text caption">(+{{ trData[tdIndex].value.length - 1 }} {{ $t('others') }})</span>
                            </template>
                          </v-autocomplete>
                        </template>
                        <template v-if="tdGrid.field.type === fieldTypeConstants.LABEL">
                          <label v-if="tdGrid.field.typedata">
                            <span v-if="tdGrid.field.typedata.calculatingValue && tdGrid.field.typedata.insertPosition === 'prefix'">{{+tdGrid.field.typedata.calculatingValue * (+rowIndexValue + 1)}}</span>
                              {{ tdGrid.field.typedata.labeltext }}
                            <span v-if="tdGrid.field.typedata.calculatingValue && tdGrid.field.typedata.insertPosition === 'suffix'">{{+tdGrid.field.typedata.calculatingValue * (+rowIndexValue + 1)}}</span>
                          </label>
                        </template>
                          <template v-if="tdGrid.field.type === fieldTypeConstants.DATE">
                          <v-menu v-model="trData[tdIndex].date_menu" :close-on-content-click="false" max-width="290">
                            <template v-slot:activator="{ on }">
                              <v-text-field dense  hide-details v-model="trData[tdIndex].value" prepend-icon="mdi-calendar" readonly v-on="on"  @click:clear="trData[tdIndex].value = null"></v-text-field>
                            </template>
                            <v-date-picker no-title v-model="trData[tdIndex].date_value" @input="trData[tdIndex].value = $formatter.parseDate(trData[tdIndex].date_value),  trData[tdIndex].date_menu = false"></v-date-picker>
                            </v-menu>
                          </template>
                      </template>
                    </td>
                  </tr>
                  <tr style="background: lightgrey" v-if="item.showSum.showSumRow && !$formatter.isEmptyObject(item.default_value.grid_data.data)">
                    <th style="height:30px" v-for="(sumRow, sumIndex) in item.default_value.grid_data.labels" :key="sumIndex">
                      <span v-if="item.showSum.sumColIndexes.includes(sumIndex)" style="font-size: 0.9rem;">
                        {{ getGridDataValuesTotal(sumIndex, item.default_value.grid_data.data) }}
                      </span>
                    </th>
                  </tr>
                </tbody>
              </v-simple-table>
              <v-btn class="mt-2" fab dark color="primary" @click="addnewLineOnGrid(index, item.label)" x-small>
                <v-icon dark>mdi-plus</v-icon>
              </v-btn>
            </template>
            <template v-if="item.type === 19">
              <survey-and-advanceSurvey :item="item" :fields="fields[item.name]"
                  :listOfDocuments="documentsList" :fromChecklistForm="true" :expandsurveysection="false"
                  @downloadDocument="downloadDocument" @uploadDocument="uplaodDocumentsHandler"
                  @deleteDocument="deleteDocument" @editDocument="editDocument">
              </survey-and-advanceSurvey>
            </template>
          </v-col>
        </template>
      </v-row>
    </v-form>
    <v-dialog v-model="updateFileDialog" persistent width="500">
      <v-card>
        <v-card-title  class="pa-2 white--text" :style="{backgroundColor: systemDetails.themecolor ? systemDetails.themecolor : '#0D47A1' }" primary-title> {{$t('updateDocument')}}</v-card-title>
          <v-card-text class="mt-2">
            <p>{{$t('document')}}: <strong>{{ updateDocument.file_original_name }}</strong></p>
            <v-file-input class="mt-2" v-model="updateFilePicker" :label="$t('document')" outlined dense></v-file-input>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-flex class="text-center">
              <v-btn small color="primary" class="mr-3" @click="updateDocumentHandler">{{$t('update')}}</v-btn>
              <v-btn small color="error" @click="updateFileDialog = false">{{$t('close')}}</v-btn>
            </v-flex>
          </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
// eslint-disable-next-line camelcase
import { checklistApi } from '@/plugins/axios_settings'
import { fieldTypeConstants } from '@/utils/support_list'
import { mapGetters } from 'vuex'
import checklist from '@/mixins/checklist'
export default {
  mixins: [checklist],
  props: {
    fields: {
      default: () => {},
      type: Object
    },
    listOfFields: {
      default: () => [],
      type: Array
    },
    documentsList: {
      default: () => {},
      type: Object
    },
    checklistId: {
      type: String,
      default: () => ''
    }
  },
  data () {
    return {
      reRender: 0,
      deleteDocumentId: 0,
      deleteDocumentName: '',
      type: 0,
      updateFileDialog: false,
      updateDocument: {},
      updateDocumentProperty: '',
      updateFilePicker: [],
      loading: false,
      documents: {},
      fieldTypeConstants: fieldTypeConstants
    }
  },
  mounted () {
    this.$root.$on('deleteRecord', (data) => {
      this.deleteDocumentRecord(data)
    })
  },
  components: {
    'survey-and-advanceSurvey': () => import('./SurveyAndAdvanceSurvey.vue'),
    'invent-component': () => import('./Invent.vue'),
    'form-render': () => import('./ChecklistFormRender.vue')
  },
  computed: {
    ...mapGetters(['systemDetails'])
  },
  methods: {
    // Common validation method
    getValidation (validate, type = null) {
      if (validate === 'required') {
        if (type) return this.$_multiSelectValidation
        else return this.$_requiredValidation
      } else return null
    },
    // adding new line to grid method type 15, 18
    addnewLineOnGrid (index, size) {
      const addLength = Object.keys(this.listOfFields[index].default_value.grid_data.data).length
      const getGrid = this.listOfFields[index].default_value.grid_data.data
      const gridData = []
      for (let i = 0; i < size; i++) {
        gridData.push({ value: '' })
      }
      const resultGrid = { ...getGrid, [`row_${addLength}`]: [...gridData] }
      this.listOfFields[index].default_value.grid_data.data = resultGrid
    },
    getGridDataValuesTotal (sumIndex, data) {
      let total = 0
      const rows = Object.keys(data)
      const allRowValues = []
      rows.forEach(key => {
        var obj = data[key].find((x, y) => y === sumIndex)
        if (obj) allRowValues.push(parseFloat(obj.value || 0))
      })
      total = this.$formatter.getSum(allRowValues)
      return total
    },
    // clearing signature pad
    clearThisSignature (id) {
      const reference = `signaturePad_${id}`
      this.$refs[reference][0].clearSignature()
    },
    // eslint-disable-next-line camelcase
    uplaodDocumentsHandler (property_id, propertyName, files, type) { // uploading documents
      if (files && files.length) {
        const documentUpload = { property_id, propertyName, type }
        documentUpload.getFiles = files
        this.documentsList[propertyName] = []
        this.documentsList[propertyName].push(documentUpload)
        this.reRender++
        this.$root.$emit('reRender', 1)
      } else {
        this.documentsList[propertyName] = []
        this.reRender++
        this.$root.$emit('reRender', 1)
      }
    },
    // download document method
    downloadDocument (id) {
      checklistApi.get(`documents/download/${id}`, this.token).then(response => {
        window.open(`${checklistApi.defaults.baseURL}files/download?fileName=${response.data}`, '_blank')
      })
    },
    // showing showDeleteDialog popup
    deleteDocument (id, propName, type) {
      this.deleteDocumentId = id
      this.deleteDocumentName = propName
      this.type = type
      const payload = { isChecklist: true }
      this.$store.commit('showDeleteDialog', payload)
    },
    // delete document method
    deleteDocumentRecord (deleteRecord) {
      if (deleteRecord && this.deleteDocumentId) {
        checklistApi.delete(`/documents/${this.deleteDocumentId}`, this.token).then(response => {
          if (response) {
            this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'deleteMsg' })
            this.$store.commit('hideDialog')
            const documents = this.$formatter.cloneVariable(this.documentsList[this.deleteDocumentName])
            if (this.$formatter.isArrayHasData(documents)) {
              const index = documents.findIndex(x => x._id === this.deleteDocumentId)
              documents.splice(index, 1)
              if (this.type === 9) {
                this.fields[this.deleteDocumentName].splice(index, 1)
              } else {
                this.fields[this.deleteDocumentName].attachments.splice(index, 1)
              }
              this.documentsList[this.deleteDocumentName] = documents
              this.reRender++
            }
          }
        }).finally(() => {
          this.$root.$emit('isloading', false)
          this.deleteDocumentId = 0
        })
      }
    },
    // edit document method
    editDocument (document, propertyName) {
      this.updateFileDialog = true
      this.updateDocument = document
      this.updateDocumentProperty = propertyName
    },
    // update doucment method
    updateDocumentHandler () {
      const formData = new FormData()
      formData.append(this.updateFilePicker.name, this.updateFilePicker)
      this.fileLoader = true
      checklistApi.post(`documents/update_attachment/${this.updateDocument._id}`, formData, this.token).then((response) => {
        if (response.data) {
          const documents = this.$formatter.cloneVariable(this.documentsList[this.updateDocumentProperty])
          if (this.$formatter.isArrayHasData(documents)) {
            const index = documents.findIndex(x => x._id === this.updateDocument._id)
            documents.splice(index, 1, response.data[0])
            this.documentsList[this.updateDocumentProperty] = documents
            if (this.documentsList[this.updateDocumentProperty] && this.documentsList[this.updateDocumentProperty].length) {
              this.documentsList[this.updateDocumentProperty].forEach(element => {
                element.imgObj = this.mimeTypeOfAttachment(element)
              })
            }
            this.reRender++
            this.$root.$emit('reRender', 1)
          }
          this.updateFileDialog = false
        }
      })
    },
    // get all public api record (for type 5  && isAPI true)
    getPublicApiData (apiObj, index) {
      let getoptions = []
      // this.loading = true
      this.$api.execute('get', apiObj.apiUrl, { headers: '' }).then((response) => {
        if (apiObj.objectName) {
          getoptions = response.data[apiObj.objectName]
        } else getoptions = response.data
        const fieldItem = this.listOfFields[index]
        fieldItem.default_value.options = []
        fieldItem.default_value.options = getoptions
        fieldItem.index = index
        fieldItem.isLoading = true
        this.$set(this.listOfFields, index, fieldItem)
      }).finally(() => {
        this.loading = false
        const fieldItem = this.listOfFields[index]
        fieldItem.isLoading = false
        this.$set(this.listOfFields, index, fieldItem)
      })
    },
    // checkbox labels width
    getCheckboxLabelWidth (options) {
      options.label = options.label || ''
      return (options.label.length + 100) > 250 ? 350 : (options.label.length + 120)
    }
  },
  beforeDestroy () {
    this.$root.$off('deleteRecord')
  }
}
</script>

<style>
.scroll-width {
  scrollbar-width: thin;
}
.checkbox-scroll {
  overflow-x:scroll !important;
  overflow-y:hidden !important
}
/* Checkbox align items baseline */
.checkbox_position > div.v-input__control > div.v-input__slot {
  align-items: flex-start;
}
</style>
